import { useMobileDevice } from "hooks";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import {
  KIOSK_SCREEN,
  STATUS,
  initSession,
  setCurrentSessionId,
  subscribeCurrentSessionId,
  subscribeSession,
} from "utils";
import _ from "lodash";
import { useGameContext } from "./useGameContext";
import { v4 as uuidv4 } from "uuid";

export const KioskContext = createContext([{}, () => null]);

export const KioskProvider = ({ children }) => {
  const isMobile = useMobileDevice();
  const [sessionId, setSessionId] = useState(null);
  const [screen, setScreen] = useState(KIOSK_SCREEN.HOME);
  const { initGameData, setEndTime } = useGameContext();
  const unsubscribeRef = useRef(null);

  useEffect(() => {
    if (isMobile !== false) return;

    const unsubscribe = subscribeCurrentSessionId(setSessionId);

    return () => {
      if (typeof unsubscribe === "function") unsubscribe();
    };
  }, [isMobile]);

  useEffect(() => {
    if (sessionId === null) return;

    init(sessionId);

    if (typeof unsubscribeRef.current === "function") unsubscribeRef.current();

    unsubscribeRef.current = subscribeSession(sessionId, onChangeSessionStatus);

    return () => {
      if (typeof unsubscribeRef.current === "function")
        unsubscribeRef.current();
    };
  }, [sessionId]);

  const init = async (sessionId) => {
    await initSession(sessionId);
    await setCurrentSessionId(sessionId);
  };

  const onChangeSessionStatus = (session) => {
    const status = _.get(session, "status", STATUS.INSTRUCTION);

    if (status === STATUS.INITIAL) setScreen(KIOSK_SCREEN.HOME);
    else if (status === STATUS.INSTRUCTION) setScreen(KIOSK_SCREEN.HOW_TO_PLAY);
    else if (status === STATUS.GAME_READY) {
      initGameData({
        cardIds: _.get(session, "cardIds", []),
        flippedCardIds: [],
        openedCardIds: [],
      });
      setScreen(KIOSK_SCREEN.GAME);
    } else if (status === STATUS.GAME_PLAYING) {
      initGameData(
        {
          cardIds: _.get(session, "cardIds", []),
          flippedCardIds: _.get(session, "flippedCardIds", []),
          openedCardIds: _.get(session, "openedCardIds", []),
        },
        true
      );
      setScreen(KIOSK_SCREEN.GAME);
    } else if (status === STATUS.GAME_OVER) {
      setEndTime(_.get(session, "endTime", "0:00"));
      setScreen(KIOSK_SCREEN.THANK_YOU);
    } else if (status === STATUS.DISCONNECTED) {
      if (typeof unsubscribeRef.current === "function") {
        unsubscribeRef.current();
        setSessionId(uuidv4());
      }
    }
  };

  return (
    <KioskContext.Provider value={{ screen, sessionId }}>
      {children}
    </KioskContext.Provider>
  );
};

export const useKioskContext = () => useContext(KioskContext);
