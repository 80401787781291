import { STATUS } from "./constants";
import { db } from "./firebase";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

const kioskId = process.env.REACT_APP_KIOSK_ID;

const getSessionDocSnapshot = async (id, raiseErrorIfNotExisting = true) => {
  if (id === null) throw Error("Session id is null.");

  const ref = doc(db, "Kiosks", kioskId, "Sessions", `${id}`);
  const docSnapshot = await getDoc(ref);

  if (raiseErrorIfNotExisting && !docSnapshot.exists())
    throw Error("Session is not existing.");

  return { ref, docSnapshot };
};

export const initSession = async (id = null) => {
  try {
    const { ref, docSnapshot } = await getSessionDocSnapshot(id, false);

    if (!docSnapshot.exists()) {
      await setDoc(ref, {
        status: STATUS.INITIAL,
      });
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

export const setCurrentSessionId = async (newId) => {
  try {
    const docSnapshot = await getDoc(doc(db, "Kiosks", kioskId));

    const { currentSessionId } = docSnapshot.exists()
      ? docSnapshot.data()
      : { currentSessionId: null };

    if (currentSessionId === newId) return;

    await setDoc(
      doc(db, "Kiosks", kioskId),
      {
        currentSessionId: newId,
      },
      { merge: true }
    );

    if (currentSessionId)
      await moveNextStep(currentSessionId, STATUS.DISCONNECTED);
  } catch (error) {
    console.log("error: ", error);
  }
};

export const moveNextStep = async (id, status) => {
  try {
    const { ref } = await getSessionDocSnapshot(id);

    await setDoc(
      ref,
      {
        status,
      },
      { merge: true }
    );
  } catch (error) {
    console.log("error: ", error);
  }
};

export const getSessionStatus = async (id) => {
  try {
    const { docSnapshot } = await getSessionDocSnapshot(id);

    return docSnapshot.data().status;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const subscribeCurrentSessionId = (callback) =>
  onSnapshot(doc(db, "Kiosks", kioskId), (doc) => {
    const kiosk = doc.data();

    callback(kiosk?.currentSessionId || uuidv4());
  });

export const subscribeSession = (id, callback) =>
  onSnapshot(doc(db, "Kiosks", kioskId, "Sessions", id), (doc) => {
    const session = doc.data();

    if (Object.values(STATUS).includes(session?.status))
      callback({
        status: session.status,
        cardIds: session?.cardIds,
        flippedCardIds: session?.flippedCardIds,
        openedCardIds: session?.openedCardIds,
        endTime: session?.endTime,
      });
  });

export const startGame = async (id) => {
  try {
    await moveNextStep(id, STATUS.GAME_PLAYING);
  } catch (error) {
    console.log("error: ", error);
  }
};

export const updateGameInfo = async (
  id,
  { cardIds, flippedCardIds, openedCardIds }
) => {
  try {
    const { ref } = await getSessionDocSnapshot(id);

    await setDoc(
      ref,
      { cardIds, flippedCardIds, openedCardIds },
      { merge: true }
    );
  } catch (error) {
    console.log("error: ", error);
  }
};

export const endGame = async (id, endTime) => {
  try {
    const { ref } = await getSessionDocSnapshot(id);

    await setDoc(
      ref,
      { endTime, openedCardIds: [], status: STATUS.GAME_OVER },
      { merge: true }
    );
  } catch (error) {
    console.log("error: ", error);
  }
};
