import clsx from "clsx";

export const KioskLayout = ({ className, children }) => {
  return (
    <div
      className={clsx(
        "relative w-full min-h-[120rem] flex flex-col p-24 bg-[#e10a0a] overflow-hidden",
        className
      )}
    >
      <div className="absolute left-24 top-[450px] opacity-20">
        <img
          className="max-w-none w-[2016px] h-auto"
          src={require("assets/images/indian-mandala.png")}
          alt="Indian Mandala"
        />
      </div>

      <div className="relative w-full">
        <div className="mb-12">
          <img src={require("assets/images/logo.png")} alt="Logo" />
        </div>

        <div className="text-Poppins-Bold text-[#fff] uppercase leading-none text-[100px]">
          DIWALICIOUS
        </div>
      </div>

      <div className="relative grow">{children}</div>
    </div>
  );
};
