import { MobileLayout } from "components";
import { useMobileContext } from "contexts";

export const MobileHowToPlay = () => {
  const { readyToPlayGame } = useMobileContext();

  return (
    <MobileLayout onClick={readyToPlayGame}>
      <div className="relative pt-16">
        <div className="text-white text-Poppins-SemiBold text-[28px] mb-4">
          HOW TO PLAY
        </div>
        <div className="text-white text-Poppins-Regular text-[28px] mb-4">
          Tap on cards to
          <br />
          reveal them.
        </div>
        <div className="text-white text-Poppins-Regular text-[28px]">
          Match the
          <br />
          pairs to win.
        </div>

        <div className="absolute top-[200px] -right-[70px]">
          <img
            className="w-[250px] h-auto"
            src={require("assets/images/HowToPlay.png")}
            alt="How To Play"
          />
        </div>
      </div>
    </MobileLayout>
  );
};
