import { useMobileDevice, useTimeout } from "hooks";
import { createContext, useContext, useEffect, useState } from "react";
import {
  MOBILE_SCREEN,
  STATUS,
  getSessionStatus,
  initSession,
  moveNextStep,
  subscribeSession,
} from "utils";
import { useGameContext } from "./useGameContext";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

export const MobileContext = createContext([{}, () => null]);

export const MobileProvider = ({ children }) => {
  const isMobile = useMobileDevice();
  const [screen, setScreen] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const {
    setSessionId: setGameSessionId,
    resetGameCardIds,
    initGameData,
    setEndTime,
  } = useGameContext();
  const [status, setStatus] = useState(null);

  useTimeout(
    async () => {
      await moveNextStep(sessionId, STATUS.DISCONNECTED);
      setScreen(MOBILE_SCREEN.DISCONNECTED);
    },
    30000,
    status === STATUS.GAME_OVER && !!sessionId
  );

  useEffect(() => {
    if (isMobile !== true) return;

    let sessionId = null;

    if (window.location.pathname === "/kiosk-game") {
      sessionId = new URLSearchParams(window.location.search).get("sessionId");

      init(sessionId);
    }

    if (window.location.pathname === "/cloud-game") {
      sessionId = uuidv4();
      initSession(sessionId).then(() => init(sessionId));
    }

    if (!sessionId) return;

    const unsubscribe = subscribeSession(sessionId, onChangeSessionStatus);

    return () => {
      if (typeof unsubscribe === "function") unsubscribe();
    };
  }, [isMobile]);

  const init = async (sessionId) => {
    if (sessionId) {
      const currentStatus = await getSessionStatus(sessionId);
      setSessionId(sessionId);
      setGameSessionId(sessionId);

      if ([STATUS.INITIAL, STATUS.HOW_TO_PLAY].includes(currentStatus)) {
        await moveNextStep(sessionId, STATUS.INSTRUCTION);
        setScreen(MOBILE_SCREEN.HOW_TO_PLAY);

        return;
      }

      await moveNextStep(sessionId, STATUS.DISCONNECTED);
    }
    setScreen(MOBILE_SCREEN.DISCONNECTED);
  };

  const onChangeSessionStatus = (session) => {
    const status = _.get(session, "status", STATUS.INSTRUCTION);
    setStatus(status);

    if (status === STATUS.INSTRUCTION) {
      setScreen(MOBILE_SCREEN.HOW_TO_PLAY);
    } else if (status === STATUS.GAME_READY) {
      initGameData({
        cardIds: _.get(session, "cardIds", []),
        flippedCardIds: [],
        openedCardIds: [],
      });
      setScreen(MOBILE_SCREEN.GAME);
    } else if (status === STATUS.GAME_PLAYING) {
      initGameData(
        {
          cardIds: _.get(session, "cardIds", []),
          flippedCardIds: _.get(session, "flippedCardIds", []),
          openedCardIds: _.get(session, "openedCardIds", []),
        },
        true
      );
      setScreen(MOBILE_SCREEN.GAME);
    } else if (status === STATUS.GAME_OVER) {
      setEndTime(_.get(session, "endTime", "0:00"));
      setScreen(MOBILE_SCREEN.THANK_YOU);
    } else if (status === STATUS.DISCONNECTED){
      setScreen(MOBILE_SCREEN.DISCONNECTED)
    }
  };

  const readyToPlayGame = async () => {
    await resetGameCardIds();
    await moveNextStep(sessionId, STATUS.GAME_READY);
  };

  return (
    <MobileContext.Provider value={{ screen, sessionId, readyToPlayGame }}>
      {children}
    </MobileContext.Provider>
  );
};

export const useMobileContext = () => useContext(MobileContext);
