export const STATUS = {
  INITIAL: "INITIAL",
  INSTRUCTION: "INSTRUCTION",
  GAME_READY: "GAME_READY",
  GAME_PLAYING: "GAME_PLAYING",
  GAME_OVER: "GAME_OVER",
  DISCONNECTED: "DISCONNECTED",
};

export const KIOSK_SCREEN = {
  HOME: "HOME",
  HOW_TO_PLAY: "HOW_TO_PLAY",
  GAME: "GAME",
  THANK_YOU: "THANK_YOU",
};

export const MOBILE_SCREEN = {
  HOW_TO_PLAY: "HOW_TO_PLAY",
  GAME: "GAME",
  THANK_YOU: "THANK_YOU",
  DISCONNECTED: "DISCONNECTED",
};
