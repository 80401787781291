import { MobileLayout } from "components";

export const MobileDisconnected = () => {
  return (
    <MobileLayout>
      <div className="text-white text-Poppins-Bold text-[30px] uppercase mt-12">
        Disconnected
      </div>

      <div className="text-white text-Poppins-SemiBold text-[24px]">
        Talk to a Virgin
        <br />
        Plus rep for a<br />
        chance to win a<br />
        new smartphone
      </div>
    </MobileLayout>
  );
};
