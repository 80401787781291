import { useMobileDevice } from "hooks";
import { shuffle } from "lodash";
import { createContext, useContext, useEffect, useState } from "react";
import { endGame, startGame, updateGameInfo } from "utils";

export const GameContext = createContext([{}, () => null]);

export const GameProvider = ({ children }) => {
  const isMobile = useMobileDevice();

  const [isStarted, setStarted] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [cardIds, setCardIds] = useState([]);
  const [flippedCardIds, setFlippedCardIds] = useState([]);
  const [openedCardIds, setOpenedCardIds] = useState([]);
  const [endTime, setEndTime] = useState("0:00");

  useEffect(() => {
    if (!isMobile) return;
    if (sessionId === null) return;
    if (!isStarted) return;

    (async () => {
      await updateGameInfo(sessionId, {
        flippedCardIds,
        cardIds,
        openedCardIds,
      });
    })();
  }, [isMobile, sessionId, isStarted, flippedCardIds, cardIds, openedCardIds]);

  useEffect(() => {
    if (!isMobile) return;
    if (flippedCardIds.length < 12) return;

    setStarted(false);
    const timeoutId = setTimeout(async () => {
      await endGame(sessionId, endTime);
    }, [1000]);

    return () => clearTimeout(timeoutId);
  }, [isMobile, sessionId, flippedCardIds]);

  const initGameData = (
    { cardIds, flippedCardIds, openedCardIds },
    started = false
  ) => {
    setCardIds(cardIds);
    setFlippedCardIds(flippedCardIds);
    setOpenedCardIds(openedCardIds);
    setStarted(started);
  };

  const resetGameCardIds = async () => {
    const cardIds = shuffle(Array.from(Array(12).keys()));
    await updateGameInfo(sessionId, {
      cardIds,
      flippedCardIds: [],
      openedCardIds: [],
    });
    setCardIds(cardIds);
    setFlippedCardIds([]);
    setOpenedCardIds([]);
  };

  const tapCard = async (id) => {
    if (openedCardIds.length >= 2) return;
    if (flippedCardIds.includes(id)) return;
    if (openedCardIds.includes(id)) return;

    if (!isStarted) {
      await startGame(sessionId);
      setStarted(true);
    }

    if (openedCardIds.length >= 1) {
      setTimeout(() => {
        setOpenedCardIds([]);
      }, [1000]);

      let isSame = false;

      for (const group of [
        [0, 1],
        [2, 3],
        [4, 5],
        [6, 7],
        [8, 9],
        [10, 11],
      ]) {
        isSame = group.includes(id) && group.includes(openedCardIds[0]);
        if (isSame) break;
      }

      if (isSame) {
        setFlippedCardIds([...flippedCardIds, openedCardIds[0], id]);
      }
    }

    setOpenedCardIds([...openedCardIds, id]);
  };

  return (
    <GameContext.Provider
      value={{
        endTime,
        setEndTime,
        isStarted,
        setStarted,
        setSessionId,
        cardIds,
        flippedCardIds,
        openedCardIds,
        resetGameCardIds,
        initGameData,
        tapCard,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

export const useGameContext = () => useContext(GameContext);
