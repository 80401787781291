import clsx from "clsx";

export const MobileLayout = ({
  className,
  children,
  onClick: handleClick = () => null,
}) => {
  return (
    <div
      className={clsx(
        "relative w-screen h-screen flex flex-col p-4 bg-[#e10a0a] overflow-hidden",
        className
      )}
      onClick={handleClick}
    >
      <div className="relative w-full">
        <div className="h-16 mt-16 mb-4">
          <img
            className="h-full w-auto"
            src={require("assets/images/logo.png")}
            alt="Logo"
          />
        </div>

        <div className="text-Poppins-Bold text-[#fff] text-[40px] uppercase leading-none">
          DIWALICIOUS
        </div>
      </div>

      <div className="absolute left-8 top-[350px] opacity-20">
        <img
          className="max-w-none w-[700px] h-auto"
          src={require("assets/images/indian-mandala.png")}
          alt="Indian Mandala"
        />
      </div>

      <div className="relative grow">{children}</div>
    </div>
  );
};
