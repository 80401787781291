import { KioskLayout } from "components";
import {
  GameProvider,
  KioskProvider,
  MobileProvider,
  useKioskContext,
  useMobileContext,
} from "contexts";
import { useMobileDevice } from "hooks";
import { Fragment } from "react";
import {
  KioskHome,
  KioskHowToPlay,
  KioskGame,
  KioskThankYou,
  MobileHowToPlay,
  MobileGame,
  MobileThankYou,
  MobileDisconnected,
} from "screens";
import { KIOSK_SCREEN, MOBILE_SCREEN } from "utils";

const MobileScreen = () => {
  const { screen } = useMobileContext();

  if (screen === MOBILE_SCREEN.HOW_TO_PLAY) return <MobileHowToPlay />;

  if (screen === MOBILE_SCREEN.GAME) return <MobileGame />;

  if (screen === MOBILE_SCREEN.THANK_YOU) return <MobileThankYou />;

  if (screen === MOBILE_SCREEN.DISCONNECTED) return <MobileDisconnected />;

  return <Fragment />;
};

const KioskScreen = () => {
  const { screen } = useKioskContext();

  if (screen === KIOSK_SCREEN.HOME) return <KioskHome />;

  if (screen === KIOSK_SCREEN.HOW_TO_PLAY) return <KioskHowToPlay />;

  if (screen === KIOSK_SCREEN.GAME) return <KioskGame />;

  if (screen === KIOSK_SCREEN.THANK_YOU) return <KioskThankYou />;

  return <Fragment />;
};

export const KioskRoutes = () => {
  const isMobile = useMobileDevice();

  if (isMobile === null) return <Fragment />;

  if (isMobile)
    return (
      <GameProvider>
        <MobileProvider>
          <MobileScreen />
        </MobileProvider>
      </GameProvider>
    );

  return (
    <GameProvider>
      <KioskProvider>
        <KioskLayout>
          <KioskScreen />
        </KioskLayout>
      </KioskProvider>
    </GameProvider>
  );
};
