export const KioskHowToPlay = () => (
  <div className="relative pt-72">
    <div className="text-white text-Poppins-SemiBold text-[70px] mb-8">
      HOW TO PLAY
    </div>

    <div className="text-white text-Poppins-Regular text-[70px] mb-8">
      Tap on cards to
      <br />
      reveal them.
    </div>

    <div className="text-white text-Poppins-Regular text-[70px]">
      Match the
      <br />
      pairs to win.
    </div>

    <div className="absolute top-[600px] -right-[200px]">
      <img src={require("assets/images/HowToPlay.png")} alt="How To Play" />
    </div>
  </div>
);
